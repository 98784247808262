<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div class="col-2">
        <select v-model="form.type" class="form-select">
          <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Number</label>
      <div class="col-3">
            <input type="text" v-model="form.number" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Pin</label>
      <div class="col-2">
            <input type="text" v-model="form.pin" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Value</label>
      <div class="col-2">
            <input type="text" v-model="form.value" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Used</label>
      <div class="col-2">
            <input type="text" v-model="form.used" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Cost</label>
      <div class="col-2">
            <input type="text" v-model="form.cost" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
          <select v-model="form.status" class="form-select col-2">
            <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { encode, decode } from "../util.js"

export default {
  name: "GiftCardEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id
      },
      breadcrumbs: [{ name: "Gift Card", link: "/giftcard" }, { name: "Update Gift Card" }],
      submitted: false,
      error: "",
      retailerList: ["Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Google", "Sony", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sephora", "eBay", "Staples"],
      statusList: ["New", "Unknown", "Failed", "Canceled", "Used"],
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      this.form.pin = encode(this.form.pin);
      axios.post("/giftcard", this.form)
        .then(() => {
          this.submitted = false;
          this.alertContent = "Gift Card has been saved successfully!";
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.submitted = false;
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
        });
    },
    getObject() {
      axios
      .get("/giftcard/" + this.form.id)
      .then((response) => {
        this.submitted = false;
        this.form = response.data;
        this.form.pin = decode(this.form.pin);
      })
      .catch((error) => {
        this.submitted = false;
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
    }
  },
};
</script>
